<template>
  <div class="container" @mouseover="play">
    <canvas ref="canvas" class="canvas" />
  </div>
</template>

<script lang="ts" setup>
import { Rive } from '@rive-app/canvas'
import { watchDebounced, useElementSize } from '@vueuse/core'
import { gsap } from 'gsap'

const props = withDefaults(
  defineProps<{
    src: string
    autoplay?: boolean
  }>(),
  {
    autoplay: true
  }
)

const canvas = ref<HTMLCanvasElement | null>(null)
const rive = ref<Rive | null>(null)

defineExpose({ rive })

const show = () => {
  gsap
    .timeline()
    .to(canvas.value, {
      opacity: 0,
      duration: 0.1,
      onComplete: () => rive.value?.resizeDrawingSurfaceToCanvas()
    })
    .to(canvas.value, { opacity: 1, duration: 0.1 })
}

const play = () => {
  rive?.value?.play()
}

onMounted(() => {
  if (!canvas.value) return

  rive.value = markRaw(
    new Rive({
      src: props.src,
      canvas: canvas.value,
      stateMachines: 'State Machine 1',
      autoplay: props.autoplay,
      onLoad: show
    })
  )
})

onUnmounted(() => rive?.value?.cleanup())

const { width, height } = useElementSize(canvas)

watchDebounced([width, height], show, { debounce: 100 })
</script>

<style lang="scss" scoped>
.canvas {
  @include size(100%);
  display: block;
  opacity: 0;
}
</style>
